<template>
  <div>
    <investment-view-header
      class="mb-1"
      :investment-data="investmentData"
      :fetch-data="fetchData"
    />
    <b-row class="mb-1">
      <b-col lg="6">
        <investment-pool-infor :investment-data="investmentData" />
      </b-col>
      <b-col lg="6">
        <investment-personal-image :investment-data="investmentData" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import store from '@/store';
import { BRow, BCol } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import InvestmentViewHeader from './InvestmentViewHeader.vue';
import InvestmentPersonalImage from './InvestmentPersonImage.vue';
import InvestmentPoolInfor from './InvestmentPoolInfor.vue';
import investmentStoreModule from '../investmentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    InvestmentViewHeader,
    InvestmentPersonalImage,
    InvestmentPoolInfor,
  },
  setup() {
    const investmentData = ref({});

    const USER_APP_STORE_MODULE_NAME = 'app-investment';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, investmentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });
    function fetchData() {
      store
        .dispatch('app-investment/fetchDetailInvestment', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          investmentData.value = response.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            investmentData.value = undefined;
          }
        });
    }
    fetchData();
    return {
      investmentData,
      fetchData,
    };
  },
};
</script>
<style lang="scss" scoped></style>
