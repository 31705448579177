<template>
  <b-card class="personal-bank-infor h-100">
    <div class="personal-bank-infor-title">
      <p>Thông tin đầu tư</p>
    </div>
    <div class="personal-bank-infor-content">
      <div class="personal-bank-infor-item">
        <p class="label">Tên nhà đầu tư</p>
        <p>{{ investmentData.customer && investmentData.customer.fullName }}</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Gói đầu tư</p>
        <p>{{ investmentData.name }}</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Số tiền</p>
        <p>{{ numberWithCommas(investmentData.amount) }}đ</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Thời gian tạo</p>
        <p>{{ investmentData.createdAt }}</p>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue';
import { numberWithCommas } from '@core/utils/utils';

export default {
  components: {
    BCard,
  },
  props: {
    investmentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      numberWithCommas,
    };
  },
};
</script>
<style lang="scss" scoped>
.personal-bank-infor {
  .personal-bank-infor-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
  .personal-bank-infor-content {
    .personal-bank-infor-item {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;
      .label {
        width: 170px;
      }
    }
  }
}
</style>
